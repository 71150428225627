<template>
  <div>
    <div style="display: flex;margin: 20% auto auto auto;width: 80%">
      <el-tag style="margin-right: 5%">&nbsp;姓&nbsp;&nbsp;&nbsp;&nbsp;名&nbsp;:</el-tag>
      <el-input v-model="name" placeholder="请输入姓名" size="mini"/>
    </div>
    <div style="display: flex;margin: 10% auto auto auto;width: 80%">
      <el-tag style="margin-right: 5%">用餐类型:</el-tag>
      <el-select v-model="meals" class="m-2" placeholder="请选择用餐类型" size="small">
        <el-option
            v-for="item in mealsType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </div>
    <div style="margin-top: 10%">
      <el-button type="primary" style="width: 100%;" @click="addMeals()">提交</el-button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  inject: ['reload'],
  name: "addMeals",
  data(){
    return{
      name: '',
      meals: '',
      mealsType: [
        {
          value: '1',
          label: '早餐',
        },
        {
          value: '2',
          label: '中餐',
        },
        {
          value: '3',
          label: '晚餐',
        },
        {
          value: '4',
          label: '早、中餐',
        },
        {
          value: '5',
          label: '早、晚餐',
        },
        {
          value: '6',
          label: '早、中、晚餐',
        },
        {
          value: '7',
          label: '中、晚餐',
        },
      ]
    }
  },
  created() {

  },
  methods:{
    addMeals(){
      let mealtype = '';
      if (this.meals === '1'){
        mealtype = '早餐';
      }
      if (this.meals === '2'){
        mealtype = '中餐';
      }
      if (this.meals === '3'){
        mealtype = '晚餐';
      }
      if (this.meals === '4'){
        mealtype = '早、中餐';
      }
      if (this.meals === '5'){
        mealtype = '早、晚餐';
      }
      if (this.meals === '6'){
        mealtype = '早、中、晚餐';
      }
      if (this.meals === '7'){
        mealtype = '中、晚餐';
      }

      this.axios.post('/newmeals/addNewMeals', (response) => {
        if (response.obj){
          ElMessage({
            message: '提交成功',
            type: 'success',
          });
          this.reload();
        }else {
          ElMessage.error('提交失败');
        }
      },{
        name: this.name,
        meals: mealtype,
        mealstype: this.meals
      })
    }
  }
}
</script>

<style scoped>

</style>